
<template>
  <v-app>
  
      <div class="row" style="width:100%;">
          <div class="col-md-6 ladoe" >
            <img src="../../assets/LOGOSVG7.svg" width="80%" style="margin-top:2%;max-width: 300px">
          </div>
          <div class="col-md-6 ladod">
              
              
              <form ref="form" class="formulario" >
              <span> <h2> Administrativo Previda </h2></span> <br/> 
              <hr/>
              <br/>
              <span> <h4>Autenticação</h4></span> <br/> 
  
              
                  
                <v-text-field  v-model="user.username" label="Usuário" placeholder="Usuário"  Required :rules="[v => !!v || 'Campo Obrigatório']"
                filled rounded dense>
                </v-text-field>
  
                <v-text-field  v-model="user.password" label="Senha" placeholder="Senha"  Required :rules="[v => !!v || 'Campo Obrigatório']"
                filled rounded dense type="password">
                </v-text-field>
               
  
                <v-btn
                  class="mr-4" color="success" @click="handleLogin" block :loading="loading" rounded>
                  Confirmar
                            <v-icon right dark>mdi-lock-open</v-icon>
                </v-btn>
               
                <v-btn style="margin-top:8px"
                  class="mr-6" block color="blue" @click="dialog_recuperar = true"  :loading="loading" rounded>
                  Recuperar Senha
                            <v-icon right dark>mdi-lock</v-icon>
                </v-btn>
  
                
                
              </form>
          </div>
  
  
      </div>
  
     
  
      <v-dialog
      v-model="dialog_recuperar"
      persistent
      max-width="480px"
        >
     
      <v-card>
        

        <v-card-title class="text-h3 blue lighten-0">
          RECUPERAR SENHA     <br/>
            </v-card-title>
        
        <br/><br/>


          <v-card-text>
            Enviaremos um SMS com a nova senha de acesso, por favor confirme os dados conforme cadastro.

          <br/>     <br/>     <br/>
            <v-container>
              <v-row>
              
                <v-col cols="12" md="12">
                    <v-text-field 
                      label="Informe Telefone"  v-mask="'(##) #####-####'"  Required :rules="[v => !!v || 'Campo Obrigatório']" style="margin-left:4px;"
                      filled v-model="recuperar.telefone"
                      prepend-inner-icon="mdi-cellphone-basic">
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-text-field 
                      label="Informe E-mail"    Required :rules="[v => !!v || 'Campo Obrigatório']" style="margin-left:4px;"
                      filled v-model="recuperar.email" 
                      prepend-inner-icon="mdi-email">
                    </v-text-field>
                  </v-col>
              </v-row>
            </v-container>
          </v-card-text>          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1"  text @click="fechar"  >
              Fechar
            </v-btn>
            <v-btn  style="background-color:#4caf50;" color=success class="white--text" :loading="this.loading"
                  @click="recupearSenha()">
                  <b>Confirmar</b>
                  <v-icon right dark>mdi-check</v-icon>
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  
      <v-dialog  v-model="alert_msg"  width="500">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Mensagem
            </v-card-title>
  
            <v-card-text>
                {{msg_dialog}}
            </v-card-text>
  
  
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="alert_msg = false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  
  
      <v-snackbar
        v-model="snackbar"
        :vertical="vertical"
      >
      Usuário e/ou senha inválidos
  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="success"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            OK
          </v-btn>
        </template>
      </v-snackbar>
  
  
  </v-app>
  </template>
  
  <style scoped>
  
  .gradient-background {
    background: linear-gradient(93deg,#d0caca,#d2d1d1);
    background-size: 120% 120%;
    animation: gradient-animation 20s ease infinite;
  }
  
  .ladoe{
    background: linear-gradient(93deg,#424242,#07318c);
    text-align:center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .ladod{
    text-align:center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .formulario{
  
    margin-top:2%;
    width:80%;
    max-width: 400px;
  }
  
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  
  }
  
  </style>
  
  <script>
  import User from '../../models/user'
  import Usuario from '../../services/usuario'
  
  export default {
      name: 'Login',
      data () {
        return {
          nameRules: [
          v => !!v || 'Campo Obrigatório',
        ],
          snackbar: false,
          alert_token: false,
          alert_senha: false,
          codigo_seguranca: '',
          load_rs: false,
          telefoneRS: '',
          alert_msg: false,
          loading: false,
          dialog_recuperar: false,

          user: new User('', ''),
          msg_dialog: '',
          recuperar:{
            telefone: '',
            email: '',
          },
          valorRules: [
          v => !!v || 'Campo obrigatório',
          ],
          data: {  
                email: '',
                senha: '',
                ip: '',
                host: '',
                tipoHost: '3',
                versao: '2.3',
          }
        }
      },
  
      methods: {
  
        salva(){
            //this.snackbar = true;
            this.$router.push('/index');
        },
  
        ativarToken(){
          this.alert_token = true;
        },
  
        recuperarSenha(){
          this.alert_senha = true;
        },

        
  
        confirmToken(){
            Usuario.ativarToken(this.codigo_seguranca).then(response => {
                    this.alert_msg = true,
                    this.loading = false,
                    this.msg_dialog = response.data.mensagem  
            }).catch(e => {
              this.alert_msg = true,
              this.msg_dialog = "Por favor, tente novamente mais tarde ..." 
            }).finally(() => {
              this.alert_token = false;
              this.codigo_seguranca = '';
            })
          },

          fechar(){
            this.dialog_recuperar = false;
          },

  
          recupearSenha(){

                this.loading = true;
                Usuario.recuperarsenha(this.recuperar).then(response => {
                  
                  this.msg_dialog = response.data;
                    this.alert_msg = true;
                    this.dialog_recuperar = false;
                    this.recuperar.telefone = "";
                    this.recuperar.email = "";

                  }).catch(e => {
                  if(e.status === 401){
                    this.loading = false;   
                  }
                }).finally(() => {
                  this.loading = false
                  this.dialog_recuperar = false;
                  })
            },
  
            handleLogin () {        

              this.loading = true
              if (this.user.username && this.user.password) {
                this.$store.dispatch('auth/login', this.user).then(
                  response => {
                      if(response.status = 200){
                        if(response.externo){
                            this.loading = false
                            this.msg_login = 'Usuário ou senha não confere',
                            this.alert_login = true
                          
                        }else{
                        this.showload = true;
                        this.$router.push('/adm/')   
                        } 
                      }
                    },
                  error => {
                    this.loading = false
                    this.msg_login = 'Usuário ou senha não confere',
                    this.alert_login = true
                  },
                )
              }
              },
  
  
      }
    }
  </script>
  
  