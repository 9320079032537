import { http } from './config';
import  { httpA}  from './configOpen';

import authHeader from './auth-header';

export default {

    lista: () => {
        return http.get('usuarios/Previda', { headers: authHeader() })
    },

    updateStatusUsuario: (id) => {
        return http.get('updateStatusUsuario/'+ id,  { headers: authHeader() })
    },

    updatePermissao: (id, roles) => {
        return http.post('permissoes/'+ id, roles, { headers: authHeader() })
    },


    updateSenhaUsuario: (senhaNova, SenhaAntiga) => {
        const params = new URLSearchParams([['senhaNova', senhaNova], ['SenhaAntiga', SenhaAntiga]])
        const user = JSON.parse(sessionStorage.getItem('user'))

        return http.get('updateSenhaUsuario/'+ user.id, { params , headers: authHeader() })
    },

    cadastro: (signUpRequest) => {
        return http.post('signup/', signUpRequest, { headers: authHeader() })

    },
   
    lista_permissoes: (user) => {
        return http.get('roles_user', user, { headers: authHeader() })
    },

    recuperarsenha: (recuperar) => {
        return httpA.post('recuperarsenhaamin', recuperar)
    },

}
